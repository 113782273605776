import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import TopBar from "../components/TopBar";
import axios from "axios";

const InitialServiceBookingPage = () => {
  const [serialNumber, setSerialNumber] = useState("");
  const [email, setEmail] = useState("");
  const [error, setError] = useState("");
  const [isLoading, setIsLoading] = useState(false); // Loading state
  const navigate = useNavigate();

  const handleSerialNumberChange = (e) => setSerialNumber(e.target.value);
  const handleEmailChange = (e) => setEmail(e.target.value);

  const validateSerialNumber = (number) => {
    const serialNum = parseInt(number, 10);
    return serialNum >= 1 && serialNum <= 9999;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsLoading(true); // Start loading

    if (!validateSerialNumber(serialNumber)) {
      setError("Serial number must be between 1 and 9999.");
      setIsLoading(false); // Stop loading on validation error
      return;
    }

    try {
      // Step 1: Get the item's data from the Circuly API using the serial number
      const itemDataResponse = await axios.get(
        `/api/circuly-data/${serialNumber}`
      );
      const itemData = itemDataResponse.data;

      // Step 2: Get the subscription data using the subscriptionID from the item data
      const subscriptionId = itemData.subscription_id;
      const subscriptionResponse = await axios.get(
        `/api/circuly-subscription/${subscriptionId}`
      );
      const subscriptionDetails = subscriptionResponse.data;

      // Step 3: Verify the provided email against the email in the subscription data
      if (
        subscriptionDetails.customer.email.toLowerCase() !== email.toLowerCase()
      ) {
        setError(
          "Kunne ikke finne kombinasjonen av sykkelnummer og epost. Ta kontakt på hei@joule.no for hjelp til å booke service."
        );
        setIsLoading(false); // Stop loading on error
        return;
      }

      // Step 4: Fetch WandaID from the internal inventory
      const inventoryResponse = await axios.get(
        `/api/inventory/${serialNumber}`
      );
      const wandaID = inventoryResponse.data.wandaID;

      if (!wandaID) {
        setError("Wanda ID not found for the provided serial number.");
        setIsLoading(false); // Stop loading on error
        return;
      }

      // Step 5: Navigate to the ServiceBookingPage with the retrieved data
      navigate("/service-booking", {
        state: {
          subscriptionID: subscriptionId,
          subscriptionDetails,
          wandaID,
        },
      });
      setIsLoading(false); // Stop loading after successful navigation
    } catch (err) {
      console.error("Error fetching subscription or inventory data:", err);
      setError("Failed to retrieve data. Please try again.");
      setIsLoading(false); // Stop loading on error
    }
  };

  // Dynamically generating the button class (disabled/enabled)
  const buttonClass = `w-38 tracking-wide font-bold rounded-lg inline-flex items-center justify-center py-2 px-6 ${
    isLoading
      ? "bg-gray-200 text-gray-400 border-gray-200 cursor-not-allowed"
      : "bg-gray-50 text-black shadow-black shadow-md hover:shadow-black hover:shadow-lg hover:scale-105"
  }`;

  return (
    <div className="flex flex-col items-center min-h-screen bg-joule mt-4 px-4">
      <TopBar />
      <h2 className="my-6 text-center text-2xl font-century-gothic text-jouletext">
        SERVICEBOOKING
      </h2>
      <div className="w-full px-4 max-w-md p-8 bg-white shadow-md rounded-lg mt-4">
        <div className="flex items-center space-x-4 mb-4">
          <div className="flex flex-col mb-4">
            <label className="block text-me font-medium text-gray-700 py-2">
              Sykkelnummer
            </label>
            <a
              href="https://joule.kb.kundo.se/guide/hvor-ser-jeg-sykkelnummeret-pa-elsykkelen-jeg-leier"
              target="_blank"
              rel="noopener noreferrer"
              className="text-gray-500 text-xs mb-2 hover:text-gray-700"
            >
              Hvor ser jeg sykkelnummeret på elsykkelen jeg leier?
            </a>
            <input
              type="text"
              value={serialNumber}
              onChange={handleSerialNumberChange}
              className="block h-14 w-full rounded-md border border-gray-500 px-3 py-2 placeholder-gray-400 shadow-sm focus:border-black"
              placeholder="Legg inn sykkelnummer"
            />
          </div>

          <a
            href="https://joule.kb.kundo.se/guide/hvor-ser-jeg-sykkelnummeret-pa-elsykkelen-jeg-leier"
            target="_blank"
            rel="noopener noreferrer"
          >
            <img
              src={process.env.PUBLIC_URL + "/pictures/joule_klistremerke.png"}
              alt="logo"
              className="object-cover w-24 h-24 rounded-lg"
            />
          </a>
        </div>

        <label className="block text-me font-medium text-gray-700 py-2">
          Epost registrert hos Joule
        </label>
        <input
          type="email"
          value={email}
          onChange={handleEmailChange}
          className="block h-14 w-full rounded-md border border-gray-500 px-3 py-2 placeholder-gray-400 shadow-sm focus:border-black"
          placeholder="Enter your email address"
        />

        {error && <p className="text-red-600 text-center mt-4">{error}</p>}

        <div className="flex justify-center mt-10">
          <button
            onClick={handleSubmit}
            className={`${buttonClass} flex justify-center items-center`}
            disabled={isLoading}
          >
            {isLoading ? (
              <>
                <div className="animate-spin rounded-full h-4 w-4 border-b-2 border-gray-900 mr-2"></div>
                Laster...
              </>
            ) : (
              "Hent informasjon"
            )}
          </button>
        </div>
      </div>
    </div>
  );
};

export default InitialServiceBookingPage;
