import React from "react";
import { useLocation } from "react-router-dom";
import { format } from "date-fns";
import nbLocale from "date-fns/locale/nb";
import TopBar from "../components/TopBar";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheckCircle } from "@fortawesome/free-solid-svg-icons";

const ServiceBookingConfirmationPage = () => {
  const location = useLocation();
  const bookingData = location.state?.bookingData || {};

  // Determine if the user selected "Wanda pickup" or "Self return"
  const isWandaPickup = bookingData.deliveryType === "wanda";

  return (
    <div className="flex flex-col min-h-screen py-4 bg-joule">
      <TopBar />
      <div className="flex-grow flex flex-col items-center justify-center px-4 sm:px-8 lg:px-8">
        <div className="bg-white p-6 rounded-lg shadow-lg text-center w-full max-w-lg">
          <FontAwesomeIcon
            icon={faCheckCircle}
            className="text-joule"
            size="3x"
          />
          <h2 className="text-2xl font-century-gothic text-jouledark mt-4 mb-6">
            Din booking er bekreftet!
          </h2>
          <div className="text-lg font-century-gothic mb-4">
            <p>Type: {bookingData.type}</p>
            <p className="mt-2">
              Innleveringsdato:{" "}
              {bookingData.bookingDate
                ? format(new Date(bookingData.bookingDate), "PP", {
                    locale: nbLocale,
                  })
                : "Ikke tilgjengelig"}
            </p>
            <p className="mt-2">
              Lokasjon:{" "}
              {isWandaPickup
                ? "Henting av sykkelen vil bli arrangert av Wanda."
                : "Gjerdrums vei 11, Nydalen"}
            </p>
          </div>

          {bookingData.note && (
            <div className="text-left mt-6 bg-gray-100 p-4 rounded-lg">
              <h3 className="font-bold text-gray-700">Dine merknader:</h3>
              <p className="mt-2 text-gray-600">{bookingData.note}</p>
            </div>
          )}

          <div className="mt-8">
            <h3 className="text-xl font-century-gothic text-jouledark mb-4">
              Hva skjer videre?
            </h3>
            <p className="text-gray-600">
              {isWandaPickup
                ? "Wanda vil sende deg en bekreftelse på henting, samt en påminnelse dagen før med et 2-timers tidsvindu for når sykkelen blir hentet. Servicen skal normalt ikke ta mer enn 2 arbeidsdager. Når servicen er ferdig, vil du motta en SMS for å avtale henting."
                : "Vi ser frem til å ta vare på sykkelen din. Servicen skal normalt ikke ta mer enn 2 arbeidsdager. Du vil motta en SMS når servicen er ferdig, slik at du kan avtale henting."}
            </p>
            <p className="mt-6 text-gray-600">
              Trenger du å endre bookingen? Send oss en e-post på{" "}
              <a
                href="mailto:hei@joule.no"
                className="text-jouledark underline hover:text-gray-500"
              >
                hei@joule.no
              </a>
              .
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ServiceBookingConfirmationPage;
