import React, { useState, useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import TopBar from "../components/TopBar";
import CalendarComp from "../components/CalendarComp";
import TypeOfDelivery from "../components/TypeOfDelivery";
import TypeOfBooking from "../components/TypeOfBooking";
import axios from "axios";

const ServiceBookingPage = () => {
  const { state } = useLocation();
  const navigate = useNavigate();
  const [errorMsg, setErrorMsg] = useState("");
  const [phoneError, setPhoneError] = useState("");
  const [isLoading, setIsLoading] = useState(false);

  const [data, setData] = useState({
    type: "Service", // Default to Service
    subscriptionID: state?.subscriptionID || "",
    circulyOrderID: state?.subscriptionDetails?.order_id || "",
    firstName:
      state?.subscriptionDetails?.customer?.address?.billing?.first_name || "",
    lastName:
      state?.subscriptionDetails?.customer?.address?.billing?.last_name || "",
    email: state?.subscriptionDetails?.customer?.email || "",
    phone: state?.subscriptionDetails?.customer?.phone || "",
    serialNumber: state?.subscriptionDetails?.serial_number || "",
    address:
      state?.subscriptionDetails?.customer?.address?.shipping?.street || "",
    postalCode:
      state?.subscriptionDetails?.customer?.address?.shipping?.postal_code ||
      "",
    city: state?.subscriptionDetails?.customer?.address?.shipping?.city || "",
    wandaID: state?.wandaID || "",
    deliveryType: "nydalen", // Default to Nydalen pickup
    wandaType: "PICK_UP",
    bookingDate: "",
    selectedTimeslot: "",
    note: "",
    winterTyres: false, // New variable added
  });

  const [wandaOrderData, setWandaOrderData] = useState({
    storageItemIds: [data.wandaID],
    type: "PICK_UP",
    orderDetails: {
      deliveryDate: "",
      deliveryTimeSlot: {
        from: "",
        to: "",
      },
      contactInfo: {
        firstName: data.firstName,
        lastName: data.lastName,
        address: {
          street: data.address,
          city: data.city,
          postalCode: data.postalCode,
          countryCode: "NO",
        },
        email: data.email,
        mobilePhone: data.phone,
      },
      locale: "no-NO",
      extraDeliveryInfo: "",
    },
  });

  const [nydalenOrderData, setNydalenOrderData] = useState({
    storageItemIds: [data.wandaID],
    type: "PICK_UP",
    orderDetails: {
      deliveryDate: "",
      deliveryTimeSlot: {
        from: "0900",
        to: "1700",
      },
      contactInfo: {
        firstName: "Joule",
        lastName: "AS",
        address: {
          street: "Økernveien 9",
          city: "Oslo",
          postalCode: "0653",
          countryCode: "NO",
        },
        email: "haavard.traa@joule.no",
        mobilePhone: "+4795949356",
      },
      locale: "no-NO",
      extraDeliveryInfo: "overleveringsordre",
    },
  });

  // Function to validate the phone number
  const validatePhoneNumber = (phoneNumber) => {
    const regex = /^\+\d{10}$/;
    if (!regex.test(phoneNumber)) {
      setPhoneError("Phone number must be in the format +47XXXXXXXX");
    } else {
      setPhoneError("");
    }
  };

  // Validate the phone number on initial load
  useEffect(() => {
    validatePhoneNumber(data.phone);
  }, [data.phone]);

  // Handle input changes for Wanda order data
  const handleInputChange = (field, value) => {
    setData((prevData) => ({
      ...prevData,
      [field]: value,
    }));

    setWandaOrderData((prevData) => {
      const updatedData = { ...prevData };
      switch (field) {
        case "phone":
          updatedData.orderDetails.contactInfo.mobilePhone = value;
          validatePhoneNumber(value);
          break;
        case "address":
          updatedData.orderDetails.contactInfo.address.street = value;
          break;
        case "city":
          updatedData.orderDetails.contactInfo.address.city = value;
          break;
        default:
          break;
      }
      return updatedData;
    });
  };

  // Synchronize delivery data with Wanda order data
  useEffect(() => {
    setWandaOrderData((prevData) => ({
      ...prevData,
      orderDetails: {
        ...prevData.orderDetails,
        deliveryDate: data.bookingDate,
        deliveryTimeSlot: data.selectedTimeslot
          ? {
              from: data.selectedTimeslot.from,
              to: data.selectedTimeslot.to,
            }
          : prevData.orderDetails.deliveryTimeSlot,
      },
    }));
  }, [data.bookingDate, data.selectedTimeslot]);

  // Synchronize delivery date with Nydalen order data
  useEffect(() => {
    if (data.bookingDate && !isNaN(Date.parse(data.bookingDate))) {
      const newDeliveryDate = new Date(data.bookingDate);
      newDeliveryDate.setDate(newDeliveryDate.getDate() + 14);
      const formattedDate = newDeliveryDate.toISOString().split("T")[0];

      setNydalenOrderData((prevData) => ({
        ...prevData,
        orderDetails: {
          ...prevData.orderDetails,
          deliveryDate: formattedDate,
        },
      }));
    }
  }, [data.bookingDate]);

  // Effect to update Wanda order postal code when data.postalCode changes
  useEffect(() => {
    if (data.postalCode) {
      setData((prevData) => ({
        ...prevData,
        bookingDate: "",
        selectedTimeslot: "",
      }));

      setWandaOrderData((prevData) => ({
        ...prevData,
        orderDetails: {
          ...prevData.orderDetails,
          contactInfo: {
            ...prevData.orderDetails.contactInfo,
            address: {
              ...prevData.orderDetails.contactInfo.address,
              postalCode: data.postalCode,
            },
          },
        },
      }));
    }
  }, [data.postalCode]);

  // useEffect to monitor changes to 'type' and handle winterTyres and note
  useEffect(() => {
    if (data.type === "Dekkskifte") {
      setData((prevData) => ({
        ...prevData,
        winterTyres: true,
        note: "DEKKSKIFT", // Automatically set the note for Dekkskifte
      }));
    } else {
      setData((prevData) => ({
        ...prevData,
        winterTyres: false, // Reset winterTyres when switching away from Dekkskifte
        note: "", // Clear the note when it's not Dekkskifte
      }));
    }
  }, [data.type]);

  // Code that updates the count on bike movements from service in the dailymovementsummary collection
  const updateDailyMovementSummary = async () => {
    const requestBody = {
      bookingDate: data.bookingDate,
      deliveryType: data.deliveryType, // "wanda" or "nydalen"
      movementType: "pickup",
      activityType: "service",
    };

    try {
      await axios.post("/api/dailyMovements/update", requestBody);
      console.log("Daily Movement Summary Updated Successfully");
    } catch (error) {
      console.error("Error updating daily movement summary:", error);
    }
  };

  // Function to handle submission of booking
  const handleSubmit = async () => {
    setIsLoading(true);
    try {
      const orderDataToPost =
        data.deliveryType === "wanda" ? wandaOrderData : nydalenOrderData;

      // Post the order data to Wanda's API
      if (data.deliveryType === "wanda") {
        await axios.post("/api/wanda-order", orderDataToPost);
      } else if (data.deliveryType === "nydalen") {
        try {
          await axios.post("/api/wanda-order", orderDataToPost);
        } catch (error) {
          // Log the error but do not treat it as critical if the delivery type is 'nydalen'
          console.warn(
            "Wanda API call failed for Nydalen delivery type, likely due to the status already being correct:",
            error.message
          );
        }
      }

      // If the delivery type is 'wanda', make a one-time payment
      if (data.deliveryType === "wanda") {
        const paymentData = {
          amount: 188,
          order_id: data.circulyOrderID,
          message: `Kostnad for transport med Wanda den ${data.bookingDate} i forbindelse med serviceavtale`,
          products: [
            {
              product: "Wanda transport",
              amount: 188,
              tax_percent: 25,
              quantity: 1,
            },
          ],
        };

        // Trigger the one-time payment API
        await axios.post("/api/circuly/one-time-payment", paymentData);
      }

      const bookingData = {
        type: data.type,
        bookingDate: data.bookingDate,
        serialNumber: data.serialNumber,
        firstName: data.firstName,
        lastName: data.lastName,
        email: data.email,
        number: data.phone,
        note: data.note,
        bookingStatus: "new", // or any other status based on your logic
        addressStreet: data.address,
        addressCity: data.city,
        addressPostalCode: data.postalCode,
        deliveryTypeFromCustomer: data.deliveryType,
        subscriptionID: data.subscriptionID, // New field
        orderID: data.circulyOrderID, // New field
        wandaID: data.wandaID, // New field
        winterTyres: data.winterTyres,
      };

      // Add the service booking to the operations system
      await axios.post(`/api/register-service-booking`, bookingData);

      // Update daily movement summary
      await updateDailyMovementSummary();

      // Navigate to confirmation page on success
      navigate("/booking-confirmation", { state: { bookingData: data } });
    } catch (error) {
      console.error("Error submitting data:", error);
      setErrorMsg(
        "Noe gikk galt. Vennligst ta kontakt med oss på hei@joule.no for videre hjelp. Vi beklager ulempen!"
      );
    }
    setIsLoading(false);
  };

  // Disable submit button if conditions are not met
  const isSubmitDisabled =
    data.deliveryType === "nydalen"
      ? !data.bookingDate
      : data.deliveryType === "wanda"
      ? !(data.bookingDate && data.selectedTimeslot)
      : true;

  const buttonClass = `buttonClass w-38 tracking-wide font-bold rounded-lg inline-flex items-center justify-center py-2 px-6 ${
    isSubmitDisabled
      ? "bg-gray-200 text-gray-400 border-gray-200 cursor-not-allowed"
      : "bg-white text-black shadow-black shadow-md hover:shadow-black hover:shadow-lg hover:scale-105"
  }`;

  return (
    <div>
      <TopBar />
      <div className="w-full h-full min-h-full flex flex-col justify-center px-4 lg:px-8 z-100 mf:h-screen">
        <h2 className="mt-6 mb-4 text-center text-lg font-century-gothic text-jouletext">
          Velg Type Service
        </h2>

        <TypeOfBooking data={data} setData={setData} />

        {data.type === "Service" && (
          <div className="flex items-center justify-center mb-4">
            <input
              type="checkbox"
              checked={data.winterTyres}
              onChange={(e) =>
                handleInputChange("winterTyres", e.target.checked)
              }
              className="mr-3 h-6 w-6 focus:ring-0"
            />
            <label className="text-white font-century-gothic text-sm">
              Jeg ønsker å bytte til vinterdekk på service (Gjelder bare dersom
              du ikke har mottatt vinterskins)
            </label>
          </div>
        )}

        <h2 className="mt-6 mb-4 text-center text-lg font-century-gothic text-jouletext">
          Velg Transportmetode
        </h2>

        <TypeOfDelivery
          data={data}
          setData={setData}
          deliveryTypeVarName="deliveryType"
          sourcePage="ServiceBookingPage"
        />

        <h2 className="mt-6 mb-4 text-center text-lg font-century-gothic text-jouletext">
          Velg Dato For Innlevering Av Sykkel
        </h2>

        <CalendarComp
          data={data}
          setData={setData}
          sourcePage="ServiceBookingPage"
        />

        <div className="flex flex-col items-center my-2">
          <h3 className="my-2 text-center text-sm font-century-gothic text-jouletext">
            Telefonnummer
          </h3>
          <input
            type="text"
            placeholder="Phone Number"
            value={data.phone}
            onChange={(e) => handleInputChange("phone", e.target.value)}
            className="my-1 text-center py-2 w-full sm:w-2/3 md:w-1/2 rounded-lg border-2 border-gray-300 placeholder:text-gray-600"
          />
          {phoneError && <div className="text-red-500">{phoneError}</div>}

          {data.deliveryType === "wanda" && (
            <>
              <h3 className="my-2 text-center text-sm font-century-gothic text-jouletext">
                Adresse
              </h3>
              <input
                type="text"
                placeholder="Street Address"
                value={data.address}
                onChange={(e) => handleInputChange("address", e.target.value)}
                className="my-1 text-center py-2 w-full sm:w-2/3 md:w-1/2 rounded-lg border-2 border-gray-300 placeholder:text-gray-600"
              />
              <h3 className="my-2 text-center text-sm font-century-gothic text-jouletext">
                Sted
              </h3>
              <input
                type="text"
                placeholder="City"
                value={data.city}
                onChange={(e) => handleInputChange("city", e.target.value)}
                className="my-1 text-center py-2 w-full sm:w-2/3 md:w-1/2 rounded-lg border-2 border-gray-300 placeholder:text-gray-600"
              />
            </>
          )}
          {data.type !== "Dekkskifte" && ( // Hide the note field if type is "Dekkskifte"
            <>
              <h3 className="mt-4 mb-2 text-center text-sm font-century-gothic text-jouletext">
                Melding til våre mekanikere
              </h3>
              <textarea
                placeholder="For å sikre best mulig service, setter vi stor pris på om du skriver ned alt du har merket – uansett om det er små ting eller større utfordringer. Alle tilbakemeldinger hjelper mekanikerne våre med å gjøre sykkelen din klar til bruk på best mulig måte."
                value={data.note}
                onChange={(e) => handleInputChange("note", e.target.value)}
                className="my-1 py-2 w-full h-40 sm:w-2/3 md:w-1/2 rounded-lg border-2 border-gray-300 placeholder:text-gray-500"
              />
            </>
          )}
        </div>

        {errorMsg && <div className="text-red-400 text-center">{errorMsg}</div>}
        <div className="flex justify-center my-4 pb-8">
          <button
            onClick={handleSubmit}
            className={`${buttonClass} flex justify-center items-center`}
            disabled={isLoading || isSubmitDisabled}
          >
            {isLoading ? (
              <>
                <div className="animate-spin rounded-full h-4 w-4 border-b-2 border-gray-900 mr-2"></div>
                Laster...
              </>
            ) : (
              "Book service"
            )}
          </button>
        </div>
      </div>
    </div>
  );
};

export default ServiceBookingPage;
