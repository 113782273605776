import React, { useState, useEffect } from "react";

const TypeOfDelivery = ({ data, setData, deliveryTypeVarName, sourcePage }) => {
  const [deliveryType, setDeliveryType] = useState(data[deliveryTypeVarName]);

  // Set the initial localPostalCode based on sourcePage and data
  const initialPostalCode =
    sourcePage === "ReturnPage" ||
    sourcePage === "ServiceBookingPage" ||
    sourcePage === "ServiceReturnPage"
      ? data.postalCode
      : "";
  const [localPostalCode, setLocalPostalCode] = useState(initialPostalCode);
  const [postalCodeChanged, setPostalCodeChanged] = useState(false);
  const [showMessage, setShowMessage] = useState(false);

  useEffect(() => {
    // Reset localPostalCode when data.postalCode changes
    if (
      sourcePage === "ReturnPage" ||
      sourcePage === "ServiceBookingPage" ||
      sourcePage === "ServiceReturnPage"
    ) {
      setLocalPostalCode(data.postalCode);
      setPostalCodeChanged(false); // Reset change flag
    }
  }, [data.postalCode, sourcePage]);

  const handleDeliveryTypeChange = (type) => {
    setDeliveryType(type);
    setData({ ...data, [deliveryTypeVarName]: type });
    if (type === "nydalen") {
      setShowMessage(false);
    }
  };

  const handlePostalCodeSubmit = () => {
    if (localPostalCode !== data.postalCode) {
      setData({ ...data, postalCode: localPostalCode });
      setShowMessage(true);
    }
  };

  // Detect changes to the postal code input field
  const handlePostalCodeChange = (e) => {
    setLocalPostalCode(e.target.value);
    setPostalCodeChanged(e.target.value !== data.postalCode);
  };

  return (
    <div className="flex flex-col my-4 font-century-gothic justify-center mx-auto w-full sm:w-2/3 md:w-1/2">
      <div className="flex">
        <div className="mr-3 w-1/2">
          <button
            className={`min-w-full max-w-xs h-16 tracking-wide text-xs md:text-sm rounded-lg border-black hover:bg-gray-100 hover:text-black py-2 px-3 md:px-6 inline-flex flex-col items-center justify-center ${
              deliveryType === "nydalen"
                ? "bg-white text-black shadow-black shadow-lg focus:transform focus:scale-105"
                : "bg-jouledark text-jouletext2"
            }`}
            onClick={() => handleDeliveryTypeChange("nydalen")}
          >
            <span>
              {sourcePage === "ReturnPage" ||
              sourcePage === "ServiceBookingPage"
                ? "Levering i Nydalen"
                : "Henting i Nydalen"}
            </span>
            {(sourcePage === "ReturnPage" ||
              sourcePage === "ServiceBookingPage" ||
              sourcePage === "ServiceReturnPage") &&
              deliveryType === "nydalen" && (
                <div
                  style={{
                    fontSize: "0.75rem",
                    fontStyle: "italic",
                    marginTop: "4px",
                  }}
                >
                  gratis,-
                </div>
              )}
          </button>
        </div>
        <div className="ml-3 w-1/2">
          <button
            className={`min-w-full max-w-xs h-16 tracking-wide text-xs md:text-sm rounded-lg border-black hover:bg-gray-100 hover:text-black py-2 px-3 md:px-6 inline-flex flex-col items-center justify-center ${
              deliveryType === "wanda"
                ? "bg-white text-black shadow-black shadow-lg focus:transform focus:scale-105"
                : "bg-jouledark text-jouletext2"
            }`}
            onClick={() => handleDeliveryTypeChange("wanda")}
          >
            <span>
              {sourcePage === "ReturnPage" ||
              sourcePage === "ServiceBookingPage"
                ? "Henting på dør med Wanda.Space"
                : "Levering på på dør med Wanda.Space"}
            </span>
            {(sourcePage === "ReturnPage" ||
              sourcePage === "ServiceBookingPage" ||
              sourcePage === "ServiceReturnPage") &&
              deliveryType === "wanda" && (
                <div
                  style={{
                    fontSize: "0.75rem",
                    fontStyle: "italic",
                    marginTop: "4px",
                  }}
                >
                  NOK 188,-
                </div>
              )}
          </button>
        </div>
      </div>
      {deliveryType === "wanda" && (
        <>
          {/* Postal code input and capacity check */}
          <div className="flex justify-center items-center pt-6">
            <div className="relative sm:w-full md:w-2/3 lg:w-1/3">
              <input
                type="text"
                placeholder={
                  sourcePage === "ReturnPage" ||
                  sourcePage === "ServiceBookingPage" ||
                  sourcePage === "ServiceReturnPage"
                    ? data.postalCode
                    : "Ditt postnr"
                }
                value={localPostalCode}
                onChange={handlePostalCodeChange}
                className="pl-2 pr-20 py-2 w-full border border-black rounded text-xs"
              />
              <button
                onClick={handlePostalCodeSubmit}
                disabled={!postalCodeChanged}
                className={`absolute right-0 top-0 h-full ${
                  postalCodeChanged
                    ? "bg-jouledark text-white hover:bg-gray-100 hover:text-black"
                    : "bg-gray-200 text-gray-400 cursor-not-allowed"
                } px-4 border-l border-black rounded-r text-xs`}
              >
                Sjekk kapasitet
              </button>
            </div>
          </div>
        </>
      )}
      {showMessage && (
        <p className="mt-6 text-center font-century-gothic text-jouletext">
          Viser ledige leveringsdager for postnummer {data.postalCode}:
        </p>
      )}
    </div>
  );
};

export default TypeOfDelivery;
