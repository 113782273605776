import React from "react";
import { useLocation } from "react-router-dom";
import { format } from "date-fns";
import nbLocale from "date-fns/locale/nb";
import TopBar from "../components/TopBar";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheckCircle } from "@fortawesome/free-solid-svg-icons";

const ReturnConfirmationPage = () => {
  const location = useLocation();
  const returnData = location.state?.returnData || {};

  // Determine if the user selected "Wanda home pickup" or "Nydalen in-store return"
  const isWandaDelivery = returnData.deliveryType === "wanda";

  // Format the return date for display
  const formattedReturnDate = returnData.deliveryDate
    ? format(new Date(returnData.deliveryDate), "PP", { locale: nbLocale })
    : "Ikke tilgjengelig";

  return (
    <div className="flex flex-col min-h-screen py-4 bg-joule">
      <TopBar />
      <div className="flex-grow flex flex-col items-center justify-center px-4 sm:px-8 lg:px-8">
        <div className="bg-white p-6 rounded-lg shadow-lg text-center w-full max-w-lg">
          <FontAwesomeIcon
            icon={faCheckCircle}
            className="text-joule"
            size="3x"
          />
          <h2 className="text-2xl font-century-gothic text-jouledark mt-4 mb-6">
            Din tilbakelevering er bekreftet!
          </h2>
          <div className="text-lg font-century-gothic mb-4">
            <p>Type: {returnData.type || "Retur av elsykkel"}</p>
            <p className="mt-2">Tilbakeleveringsdato: {formattedReturnDate}</p>
            <p className="mt-2">
              Lokasjon:{" "}
              {isWandaDelivery
                ? "Henting på dør av vår partner, Wanda."
                : "Gjerdrums vei 11, Nydalen"}
            </p>
          </div>

          <div className="mt-8">
            <h3 className="text-xl font-century-gothic text-jouledark mb-4">
              Hva skjer videre?
            </h3>
            <p className="text-gray-600">
              {isWandaDelivery
                ? "Vår transportpartner, Wanda, vil hente sykkelen hos deg på den avtalte datoen. Du vil motta en bekreftelse og en påminnelse dagen før henting, inkludert et 2-timers tidsvindu for henting. Takk for at du valgte Joule, vi håper du har hatt stor glede av elsykkelen!"
                : "Du kan levere sykkelen til vårt servicesenter i Gjerdrums vei 11 på den avtalte datoen. Våre åpningstider er 10:00 - 17:00. Takk for at du syklet med Joule, vi håper du har hatt stor glede av elsykkelen!"}
            </p>
            <p className="mt-6 text-gray-600">
              Trenger du å gjøre endringer til din tilbakelevering? Send oss en
              e-post på{" "}
              <a
                href="mailto:hei@joule.no"
                className="text-jouledark underline hover:text-gray-500"
              >
                hei@joule.no
              </a>
              .
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ReturnConfirmationPage;
