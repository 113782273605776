import React from "react";
import { useLocation } from "react-router-dom";
import { format } from "date-fns";
import nbLocale from "date-fns/locale/nb";
import TopBar from "../components/TopBar";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheckCircle } from "@fortawesome/free-solid-svg-icons";

const ServiceReturnConfirmationPage = () => {
  const location = useLocation();
  const bookingData = location.state?.bookingData || {};

  // Determine if the user selected "Wanda delivery" or "Nydalen pickup"
  const isWandaDelivery = bookingData.deliveryType === "wanda";

  return (
    <div className="flex flex-col min-h-screen py-4 bg-joule">
      <TopBar />
      <div className="flex-grow flex flex-col items-center justify-center px-4 sm:px-8 lg:px-8">
        <div className="bg-white p-6 rounded-lg shadow-lg text-center w-full max-w-lg">
          <FontAwesomeIcon
            icon={faCheckCircle}
            className="text-joule"
            size="3x"
          />
          <h2 className="text-2xl font-century-gothic text-jouledark mt-4 mb-6">
            Din utlevering er bekreftet!
          </h2>
          <div className="text-lg font-century-gothic mb-4">
            <p>Type: {bookingData.type || "Service"}</p>
            <p className="mt-2">
              Utleveringsdato:{" "}
              {bookingData.returnDate
                ? format(new Date(bookingData.returnDate), "PP", {
                    locale: nbLocale,
                  })
                : "Ikke tilgjengelig"}
            </p>
            <p className="mt-2">
              Lokasjon:{" "}
              {isWandaDelivery
                ? "Levering av sykkelen vil bli arrangert av Wanda."
                : "Gjerdrums vei 11, Nydalen"}
            </p>
          </div>

          {bookingData.note && (
            <div className="text-left mt-6 bg-gray-100 p-4 rounded-lg">
              <h3 className="font-bold text-gray-700">Dine merknader:</h3>
              <p className="mt-2 text-gray-600">{bookingData.note}</p>
            </div>
          )}

          <div className="mt-8">
            <h3 className="text-xl font-century-gothic text-jouledark mb-4">
              Hva skjer videre?
            </h3>
            <p className="text-gray-600">
              {isWandaDelivery
                ? "Wanda vil sende deg en bekreftelse på levering, samt en påminnelse dagen før med et 2-timers tidsvindu for når sykkelen blir levert."
                : "Vi ser frem til å returnere sykkelen din i topp stand. Husk å komme innom i våre åpningstider mellom 10-17."}
            </p>
            <p className="mt-6 text-gray-600">
              Trenger du å endre utleveringen? Send oss en e-post på{" "}
              <a
                href="mailto:hei@joule.no"
                className="text-jouledark underline hover:text-gray-500"
              >
                hei@joule.no
              </a>
              .
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ServiceReturnConfirmationPage;
